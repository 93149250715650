'use-strict'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './styles/index.scss';
import App from './App';
import { store, persistor } from './helpers';
import { Spinners, ErrorBoundary, NetworkError } from './components';
import * as serviceWorker from './serviceWorker';
import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Montserrat:400,500,600', 'sans-serif', 'Georgia']
  }
});


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<Spinners.FullScreenSpinner isLoading={true} />}>
        <ErrorBoundary>
          <NetworkError>
            <App />
          </NetworkError>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
